<template>
  <PanelSection title="Detalles activación" :hide-back-button="false">
    <div class="company-activations-form">
      <v-container>
        <!-- Content -->
        <CustomCard>
          <dl v-if="companyActivation" class="content">
            <dt>Area</dt>
            <dd>{{ companyActivation.area || '-' }}</dd>
            <!-- Datos comercial -->
            <dt>Nombre comercial</dt>
            <dd>{{ companyActivation.commercialName || '-' }}</dd>
            <dt>Email comercial</dt>
            <dd>{{ companyActivation.commercialEmail || '-' }}</dd>
            <!-- Codigo compañía -->
            <dt>Código</dt>
            <dd>{{ companyActivation.companyKeysId || '-' }}</dd>
            <!-- Compañía -->
            <dt>Compañía</dt>
            <dd>{{ companyActivation.companyName || '-' }}</dd>
            <!-- Email -->
            <dt>Email</dt>
            <dd>{{ companyActivation.email || '-' }}</dd>
            <!-- Teléfono -->
            <dt>Phone</dt>
            <dd>{{ companyActivation.phone || '-' }}</dd>
            <!-- Dirección -->
            <dt>Dirección</dt>
            <dd>{{ companyActivation.address || '-' }}</dd>
            <!-- CP -->
            <dt>Código postal</dt>
            <dd>{{ companyActivation.postalCode || '-' }}</dd>
            <!-- Tipo -->
            <dt>Tipo</dt>
            <dd>
              <v-select
                v-model="companyActivation.type"
                :items="availableTypes"
                item-value="value"
                item-text="label"
                hide-details
                dense
                color="grey"
                class="mt-0"
                @change="handleChangeType"
              />
            </dd>
            <!-- Estado -->
            <dt>Estado</dt>
            <dd>
              <v-select
                v-if="availableStatus.length > 0"
                v-model="companyActivation.status"
                :items="availableStatus"
                item-value="value"
                item-text="label"
                hide-details
                dense
                color="grey"
                class="mt-0"
                @change="handleChangeStatus"
              />
            </dd>
            <!-- Observaciones rechazada -->
            <template
              v-if="companyActivation.type === activationTypes.rejected.value"
            >
              <dt>Motivo rechazo</dt>
              <dd>{{ companyActivation.rejectionText || '-' }}</dd>
            </template>
            <!-- Observaciones terminada -->
            <template
              v-if="
                companyActivation.type === activationTypes.assistant.value &&
                  companyActivation.status === activationStatus.done.value
              "
            >
              <dt>Carta terminada</dt>
              <dd>{{ companyActivation.doneText || '-' }}</dd>
            </template>
            <!-- Observaciones -->
            <dt>Observaciones</dt>
            <dd>{{ companyActivation.supportText || '-' }}</dd>
            <!-- Url -->
            <template v-if="companyActivation.placeUrl">
              <dt class="no-border">
                Url
                <span v-if="isBakarta">(Bakarta)</span>
              </dt>
              <dd class="no-border">
                <a :href="companyActivation.placeUrl" target="_blank">{{
                  companyActivation.placeUrl
                }}</a>
              </dd>
            </template>
            <!-- Imágenes -->
            <template v-if="areThereImages">
              <dt class="no-border">Imágenes</dt>
              <dd class="images no-border">
                <a
                  v-for="(image, index) in companyActivation.images"
                  :key="index"
                  :href="image"
                  target="_blank"
                >
                  <v-img :src="image" />
                </a>
              </dd>
            </template>
          </dl>

          <v-card-actions>
            <!-- Form action buttons -->
            <FormButtons
              :accept-button-hide="true"
              :delete-button-hide="false"
              @onClickCancelButton="handleCancelButton"
              @onClickDeleteButton="handleDeleteButton"
            />
          </v-card-actions>
        </CustomCard>
      </v-container>
    </div>
  </PanelSection>
</template>

<script>
// Constants
import { ACTIVATION_TYPES, ACTIVATION_STATUS } from '@/constants'
// Components
import PanelSection from '@/components/ui/PanelSection'
import CustomCard from '@/components/ui/CustomCard'
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Services
import {
  getPanelCompanyActivationById,
  delPanelCompanyActivationsById,
  updatePanelCompanyActivations
} from '@/services/panelCompanyActivations'
// Utils
import { isBakartaDomain } from '@/utils'

export default {
  name: 'CompanyActivationsForm',
  components: {
    PanelSection,
    CustomCard,
    FormButtons
  },
  mixins: [uiMixin],
  data() {
    return {
      activationStatus: ACTIVATION_STATUS,
      activationTypes: ACTIVATION_TYPES,
      id: this.$route.params.id || null,
      companyActivation: null
    }
  },
  computed: {
    /**
     * Tipos disponibles
     *
     * @return {array}
     */
    availableTypes() {
      return Object.values(ACTIVATION_TYPES)
    },
    /**
     * Estados disponibles
     *
     * @return {array}
     */
    availableStatus() {
      return this.getAvailableStatus(this.companyActivation.type)
    },
    /**
     * Are there images in companyActivations
     *
     * @return {boolean}
     */
    areThereImages() {
      return (
        Array.isArray(this.companyActivation.images) &&
        this.companyActivation.images.length
      )
    },
    /**
     * Show options bakarta domain
     *
     * @return {boolean}
     */
    isBakarta() {
      return (
        this.companyActivation &&
        this.companyActivation.placeUrl &&
        isBakartaDomain(this.companyActivation.placeUrl)
      )
    }
  },
  async mounted() {
    if (!this.id) {
      this.goToList()
    }

    try {
      // Get data
      this.companyActivation = await getPanelCompanyActivationById(this.id)
      // Throw error
      if (!this.companyActivation) {
        throw new Error('No existe una activación con dicho ID')
      }
    } catch (error) {
      this.handleError(error)
    }
  },
  methods: {
    /**
     * Handle on cancel button
     */
    handleCancelButton() {
      this.goToList()
    },
    /**
     * Handle on delete button
     */
    async handleDeleteButton() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          await delPanelCompanyActivationsById(this.id)
          this.goToList()
        },
        actionButtonText: 'Borrar',
        dismissibleTimeOut: -1,
        text: '¿Desea borrar la activación?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(message) {
      this.modifyAppAlert({
        dismissibleTimeOut: -1,
        text: message,
        type: 'error',
        visible: true
      })
      // Redirect
      this.goToList()
    },
    /**
     * Cuando el usuario cambia el tipo de la activación
     *
     * @param {string} value - tipo de la activación
     */
    async handleChangeType(value) {
      // Modificamos tipo en BD
      await updatePanelCompanyActivations({
        id: this.companyActivation.id,
        type: value
      })
      this.companyActivation.type = value
      // Modificamos el estado
      const everyStatus = this.getAvailableStatus(value)
      await this.handleChangeStatus(
        everyStatus.length > 0 ? everyStatus[0].value : null
      )
    },
    /**
     * Cuando el usuario cambia el estado de la activación
     *
     * @param {string} value - estado de la activación
     */
    async handleChangeStatus(value) {
      // Modificamos estado en BD
      await updatePanelCompanyActivations({
        id: this.companyActivation.id,
        status: value
      })
      this.companyActivation.status = value
    },
    /**
     * Obtiene los estados disponibles, pasando el tipo
     *
     * @param {string} type - tipo de activación
     * @return {array}
     */
    getAvailableStatus(type) {
      if (type === ACTIVATION_TYPES.assistant.value) {
        return Object.values(ACTIVATION_STATUS)
      } else if (type === ACTIVATION_TYPES.self.value) {
        return Object.values(ACTIVATION_STATUS).filter(state => {
          return (
            state.value === ACTIVATION_STATUS.pending.value ||
            state.value === ACTIVATION_STATUS.done.value
          )
        })
      }

      return []
    },
    /**
     * Return to company activations list
     */
    goToList() {
      this.routerPushTo({
        path: `/company-activations`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
.company-activations-form {
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    font-size: 0.85rem;
    & > dt {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      padding: 0 0.5rem;
      flex: 0 0 20%;
      min-height: 50px;
      max-width: 20%;
      font-weight: bold;
      border-bottom: 1px solid $theme-border-color;
      &.no-border {
        border-bottom: 0;
      }
    }
    & > dd {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      padding: 0 0.5rem;
      min-height: 50px;
      flex: 0 0 80%;
      max-width: 80%;
      border-bottom: 1px solid $theme-border-color;
      &.no-border {
        border-bottom: 0;
      }
    }
  }
  .images {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    .v-image {
      width: 150px;
      height: 150px;
      margin: 0 6px 6px 0;
    }
  }
}
</style>
